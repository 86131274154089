<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage academic event</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Academic event
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                    @click="addAcademicEvent"
                    v-if="checkIsAccessible('academic-event', 'create')"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    academic event </v-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="search.title"
                    label="Search by title"
                    outlined
                    clearable
                    v-on:keyup.enter="getAcademicEvents()"
                    dense
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.type"
                    label="Type"
                    outlined
                    clearable
                    :items="types"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getAcademicEvents()"
                    dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                          v-model="search.date"
                          label="Date range"
                          prepend-inner-icon="mdi-calendar"
                          prepend-icon=""
                          clearable
                          multiple
                          chips
                          small-chips
                          outlined
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                        v-model="search.date"
                        @input="minDate"
                        :min="date"
                        no-title
                        scrollable
                        range
                    >
                    </v-date-picker>
                  </v-menu>

                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.is_active"
                    label="Status"
                    outlined
                    clearable
                    :items="status"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getAcademicEvents()"
                    dense
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-btn
                    :loading="loading"
                    @click.prevent="searchAcademicEvents()"
                    class="btn btn-primary w-35 float-right"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div>
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Start time</th>
                    <th class="px-3 wrap-column">End time</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="academicEvents.length > 0"
                    v-for="(item, index) of academicEvents"
                    :key="index"
                  >
                    <td class="px-3 wrap-column">
                      {{ item.title }}
                    </td>
                    <td class="px-3 wrap-column">
                      {{ item.type_text }}
                    </td>
                    <td class="px-3 wrap-column">
                      {{ item.start_date_time ? item.start_date_time : null }}
                    </td>       <td class="px-3 wrap-column">
                       {{ item.end_date_time ? item.end_date_time : null }}
                    </td>
                    <td class="px-3 wrap-column pt-3">
                      <span
                        class="badge text-lg"
                        :class="item.is_active ? 'badge-success' : 'badge-danger'"
                      >
                        {{ item.is_active ? "Active" : "Inactive" }}
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-event', 'edit')">
                            <a
                              href="#"
                              class="navi-link"
                              @click="editAcademicEvent(item.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-event', 'delete')">
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteAcademicEvent(item.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-delete"></i>
                              </span>
                              <span class="navi-text"> Delete </span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </td>
                  </tr>
                  <tr v-if="academicEvents.length == 0">
                    <td class="text-center px-3" colspan="6">Data not available</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-show="academicEvents.length > 0"
                @input="getAcademicEvents"
                class="pull-right mt-7"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <create-and-update
      ref="academic-event"
      @refresh="getAcademicEvents"
    ></create-and-update>
  </v-app>
</template>

<script>
import AcademicEventService from "@/core/services/academic-event/AcademicEventService";
import CreateAndUpdate from "./CreateAndUpdate";

const academicEvent = new AcademicEventService();

export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      search: {
        title: "",
        type: "",
        date: [],
        academic_year_id: "",
        program_id: "",
        is_active: "",
      },
      loading: false,
      date: null,
      menu: false,
      academicEvents: [],
      page: null,
      perPage: null,
      total: null,
      types: [
        {
          title: "Event",
          value: "event",
        },
        {
          title: "Public holiday",
          value: "public_holiday",
        },
      ],
      status: [
        {title: 'Active', value: '1'},
        {title: 'Inactive', value: '0'},
      ],
    };
  },
  methods: {
    minDate() {
      this.menu = false;
      if(this.search.date && this.search.date[0]) {
        this.date = this.search.date[0];
      }
    },

    searchAcademicEvents() {
      this.page = 1;
      this.getAcademicEvents();
    },
    getAcademicEvents() {
      this.loading = true;

      academicEvent
        .paginate(this.search, this.page)
        .then((response) => {
          this.academicEvents = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addAcademicEvent() {
      this.$refs["academic-event"].showModal();
    },
    editAcademicEvent(id) {
      this.$refs["academic-event"].showModal(id);
    },
    deleteAcademicEvent(academicEventId) {
      this.$confirm({
        message: `Are you sure you want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            academicEvent
              .delete(academicEventId)
              .then((response) => {
                this.$snotify.success("Successfully deleted");
                this.getAcademicEvents();
              })
              .catch((error) => {});
          }
        },
      });
    },
    getPrograms() {
      program
        .all()
        .then((res) => {
          this.programs = res.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getAcademicEvents();
  },
};
</script>
